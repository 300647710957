<template>
  <div class="instagram-auth">
    <button 
      v-if="!loading"
      @click="handleAuthClick" 
      :disabled="!authUrl"
    >
      {{ buttonText }}
    </button>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useSettingsStore } from "../store";
import { storeToRefs } from 'pinia';

const settings = useSettingsStore();
const { BACK_URL } = storeToRefs(settings);
const route = useRoute();

const authUrl = ref('');
const loading = ref(true);
const tokenStatus = ref(null);
const buttonText = ref('Authorize Instagram');

const emit = defineEmits(['saveBeforeAuth']);

const getAuthUrl = async () => {
  try {
    const calleeId = route.params.callee_id;
    const response = await axios.get(`${BACK_URL.value}/ig/instagram/auth_url`, {
      params: { state: 'callee_update' }
    });
    authUrl.value = response.data.auth_url;
  } catch (error) {
    console.error('Error fetching Instagram auth URL:', error);
  }
};

const checkTokenStatus = async () => {
  loading.value = true;
  try {
    const calleeId = route.params.callee_id;
    const response = await axios.get(`${BACK_URL.value}/ig/check_token/${calleeId}`);
    
    tokenStatus.value = response.data.status;
    
    // Set button text based on status
    switch (response.data.status) {
      case 'valid':
        buttonText.value = `Connected as @${response.data.username}`;
        break;
      case 'invalid':
        buttonText.value = 'Reconnect Instagram';
        break;
      case 'nonexistent':
        buttonText.value = 'Connect Instagram';
        break;
    }
  } catch (error) {
    console.error('Error checking token status:', error);
    buttonText.value = 'Connect Instagram';
  } finally {
    loading.value = false;
  }
};

const handleAuthClick = async () => {
  emit('saveBeforeAuth');
  
  setTimeout(() => {
    if (authUrl.value) {
      window.open(authUrl.value, '_blank');
    }
  }, 500);
};

onMounted(async () => {
  await getAuthUrl();
  await checkTokenStatus();
});
</script>

<style scoped>
.instagram-auth {
  display: flex;
  flex-direction: column;
  align-items: center;
}


</style>
