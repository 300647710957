<template>
  <Modal />
  <div class="grid-table-container">
    <div class="dev-buttons">
      <div><button @click="clearData" class="dev-button">Clear Data (Dev)</button></div>
      <div><button @click="fillTestData" class="dev-button">Fill Test Data (Dev)</button></div>
    </div>
    <div class="grid-table">
      <TopBar />
      <div class="grid-table-cell-wide column">
        <div class="container">
          <img src="../assets/follow-granny-logo.png" alt="Follow Granny Logo" class="logo" />
          <h2>{{ ui_string.family_ig_title }}</h2>
          <p>{{ ui_string.family_ig_step1 }}</p>
          <input type="text" :placeholder="ui_string.family_ig_callee_ig" v-model="signupData.family_slug" />
          <input type="text" :placeholder="ui_string.family_ig_callee_password" v-model="signupData.family_password" />
          <p>{{ ui_string.family_ig_step2 }}</p>
          <input type="text" :placeholder="ui_string.family_ig_callee_phone" v-model="signupData.callee_phone" />
          <p>{{ ui_string.family_ig_step3 }}</p>
          <div class="time-picker-container">
            <input type="time" v-model="signupData.preferred_time" class="time-picker" />
            <select v-model="signupData.family_timezone" class="timezone-picker">
              <option value="5">Eastern Time</option>
              <option value="6">Central Time</option>
              <option value="7">Mountain Time</option>
              <option value="8">Pacific Time</option>
            </select>
          </div>
          <div v-if="signupData.ig_username" class="connected-account">
            Connected Instagram: <strong>{{ signupData.ig_username }}</strong>
          </div>
          <div v-if="igAuthStatus === 'failed'" class="error">
            IG Auth Failed
          </div>
          <button @click="handleAllowConnection" class="ig-auth-button" :disabled="signupData.ig_verified">
            {{ signupData.ig_verified ? 'Instagram Connected ✓' : ui_string.family_ig_connect }}
          </button>
        </div>

        <div class="container">
          <h2>{{ ui_string.family_ig_step4 }}</h2>
          <p>{{ ui_string.family_ig_step4_prompt }}</p>
          <input type="text" placeholder="Your name" v-model="signupData.caller_name" />
          <input type="text" :placeholder="ui_string.family_caller_phone" v-model="signupData.caller_phone" />
          <button @click="initiateCallerIdVerification"
            :disabled="twilioIsVerificationInProgress || signupData.caller_verified">
            {{ signupData.caller_verified ? ui_string.family_ig_code_verified : (twilioIsVerificationInProgress ?
              'Verifying...' : ui_string.family_ig_code_get) }}
          </button>

          <div v-if="twilioVerificationCode" class="info">
            {{ ui_string.family_ig_code_get_prompt }}
            <strong><span class="verification-code">{{ twilioVerificationCode }}</span></strong>
          </div>
          <div v-if="twilioVerificationError" class="error">{{ twilioVerificationError }}</div>

          <p>{{ ui_string.family_ig_step5 }}</p>
          <div v-for="(_, index) in 3" :key="index">
            <input type="text" :placeholder="ui_string.family_ig_guest1" v-model="signupData.ig_white_list[index]" />
          </div>

          <p class="helper-text">{{ ui_string.family_ig_step6 }}</p>

          <p class="login-instructions">{{ ui_string.family_ig_step7 }}</p>
          <div class="login-details">
            <div>{{ ui_string.family_ig_name_title }} <span class="credential">{{ signupData.family_slug }}</span></div>
            <div>{{ ui_string.family_ig_password_title }} <span class="credential">{{ signupData.family_password
                }}</span></div>
          </div>
          <button class="login-button" @click="handleLoginToStoryCall"
            :disabled="!signupData.ig_verified || !signupData.caller_verified">
            {{ ui_string.family_ig_login }}
          </button>
        </div>
        <pre>{{ signupData }}</pre>

      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, onUnmounted } from 'vue';
import Modal from './Modal.vue';
import TopBar from './TopBar.vue';
import axios from 'axios';
import { useSettingsStore } from "../store";
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';

const settings = useSettingsStore();
const { BACK_URL, ui_string } = storeToRefs(settings);

// Create initial state object
const initialSignupState = {
  family_slug: '',
  family_password: '',
  ig_verified: true,
  ig_username: '',
  callee_phone: '',
  caller_verified: true,
  caller_verification_code: '',
  family_timezone: 5,
  caller_phone: '',
  caller_name: '',
  ig_white_list: ['', '', ''],
  preferred_time: '16:00'
};

// Use the initial state object for the ref
const signupData = ref({ ...initialSignupState });

const authUrl = ref('');
const loading = ref(false);

const route = useRoute();
const igAuthStatus = ref(route.query.ig_auth_status || null);

const twilioVerificationCode = ref('');
const twilioVerificationError = ref('');
const twilioIsVerificationInProgress = ref(false);
const twilioVerificationCheckInterval = ref(null);
const twilioIsVerified = ref(false);

const router = useRouter();
const showModal = ref(false);
const timezone = ref('America/New_York');

// Update the watch effect to handle both success and failed states
watch(igAuthStatus, (newStatus) => {
  if (newStatus === 'success') {
    signupData.value.ig_verified = true;
    signupData.value.ig_username = route.query.ig_username || '';
  } else if (newStatus === 'failed') {
    signupData.value.ig_verified = false;
    signupData.value.ig_username = '';
  }
});

const getAuthUrl = async () => {
  try {
    const response = await axios.get(`${BACK_URL.value}/ig/instagram/auth_url`, {
      params: { state: 'ig_signup' }
    });
    authUrl.value = response.data.auth_url;
  } catch (error) {
    console.error('Error fetching Instagram auth URL:', error);
  }
};

const handleAllowConnection = async () => {
  loading.value = true;
  await getAuthUrl();

  if (authUrl.value) {
    // Save current signup data before redirecting
    localStorage.setItem('signupData', JSON.stringify(signupData.value));
    window.location.href = authUrl.value;
  }
  loading.value = false;
};

// Also update the onMounted check
onMounted(async () => {
  const storedData = localStorage.getItem('signupData');
  if (storedData) {
    signupData.value = JSON.parse(storedData);
  }
  if (route.query.ig_auth_status === 'success') {
    signupData.value.ig_verified = true;
    signupData.value.ig_username = route.query.ig_username || '';
  } else if (route.query.ig_auth_status === 'failed') {
    signupData.value.ig_verified = false;
    signupData.value.ig_username = '';
  }
  await getAuthUrl();
  

});

// Save data when it changes
watch(signupData, (newData) => {
  localStorage.setItem('signupData', JSON.stringify(newData));
}, { deep: true });

// Add clear function
const clearData = () => {
  localStorage.removeItem('signupData');
  signupData.value = { ...initialSignupState };
};

const initiateCallerIdVerification = async () => {
  try {
    twilioVerificationError.value = '';
    twilioIsVerificationInProgress.value = true;
    const response = await axios.post(`${BACK_URL.value}/twilio/caller_id/add`, {
      phone_number: signupData.value.caller_phone,
      friendly_name: signupData.value.caller_name
    });
    twilioVerificationCode.value = response.data.validation_code;
    signupData.value.caller_verification_code = response.data.validation_code;

    // Start polling for verification status
    startVerificationCheck();
  } catch (error) {
    twilioVerificationError.value = error.response?.data?.detail || 'Verification failed';
  } finally {
    twilioIsVerificationInProgress.value = false;
  }
};

const startVerificationCheck = () => {
  // Clear any existing interval
  if (twilioVerificationCheckInterval.value) {
    clearInterval(twilioVerificationCheckInterval.value);
  }

  twilioVerificationCheckInterval.value = setInterval(async () => {
    try {
      const response = await axios.get(`${BACK_URL.value}/twilio/caller_ids`, {
        params: { phone_number: signupData.value.caller_phone }
      });

      if (response.data.length > 0) {
        // Phone number is verified
        twilioIsVerified.value = true;
        signupData.value.caller_verified = true;
        clearInterval(twilioVerificationCheckInterval.value);
        twilioVerificationCode.value = '';
        twilioVerificationError.value = '';
      }
    } catch (error) {
      console.error('Error checking verification status:', error);
    }
  }, 5000); // Check every 5 seconds
};

// Clean up interval when component is unmounted
onUnmounted(() => {
  if (twilioVerificationCheckInterval.value) {
    clearInterval(twilioVerificationCheckInterval.value);
  }
});

const handleLoginToStoryCall = async () => {
  if (!signupData.value.ig_verified || !signupData.value.caller_verified) {
    alert('Please complete Instagram connection and phone verification first.');
    return;
  }

  showModal.value = true;
  try {
    // Create family with whitelist
    const familyResponse = await axios.post(`${BACK_URL.value}/family/family_create`, {
      name: signupData.value.family_slug,
      family_slug: signupData.value.family_slug,
      password: signupData.value.family_password,
      family_instruct: '',
      ig_white_list: signupData.value.ig_white_list.filter(username => username.trim() !== '')
    });

    const familyId = familyResponse.data.id;

    // Create caller
    const callerResponse = await axios.post(`${BACK_URL.value}/caller/caller_create`, {
      name: signupData.value.caller_name,
      phone_number: signupData.value.caller_phone,
      family_id: familyId,
      el_voice_id: "9RpXYdocFG8u7K3pqNxi",
      caller_instruct: '',
      ig_username: signupData.value.caller_name
    });

    // Create callee
    const calleeResponse = await axios.post(`${BACK_URL.value}/callee/callee_create`, {
      callee_name: signupData.value.family_slug,
      phone_number: signupData.value.callee_phone,
      family_id: familyId,
      caller_id: callerResponse.data.id,
      background_text: '',
      callee_instruct: '',
      ig_username: signupData.value.ig_username
    });

    // Create schedule slot schedule
    await axios.post(`${BACK_URL.value}/schedule/schedule_slot_schedule_update`, {
      callee_id: calleeResponse.data.id,
      caller_id: callerResponse.data.id,
      time: signupData.value.preferred_time,
      sun: true,
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
      sat: true
    });

    // Update family settings
    await axios.put(`${BACK_URL.value}/settings/family_update/${familyId}`, {
      timezone: signupData.value.family_timezone,
    });

    // // Create initial Instagram post
    // try {
    //   await axios.post(`${BACK_URL.value}/ig/post_initial_create`, {
    //     callee_id: calleeResponse.data.id
    //   });
    // } catch (igError) {
    //   console.error('Failed to create initial Instagram post:', igError);
    // }

    // Set cookies for automatic login
    const expires = "expires=Fri, 31 Dec 9999 23:59:59 GMT";
    document.cookie = `family_slug=${signupData.value.family_slug};path=/;domain=.storycall.org;${expires}`;
    document.cookie = `family_id=${familyId};path=/;domain=.storycall.org;${expires}`;
    document.cookie = `caller_id=${callerResponse.data.id};path=/;domain=.storycall.org;${expires}`;
    document.cookie = `caller_name=${signupData.value.caller_name};path=/;domain=.storycall.org;${expires}`;
    document.cookie = `caller_phone_number=${signupData.value.caller_phone};path=/;domain=.storycall.org;${expires}`;
    document.cookie = `callee_id=${calleeResponse.data.id};path=/;domain=.storycall.org;${expires}`;
    document.cookie = `callee_name=${signupData.value.family_slug};path=/;domain=.storycall.org;${expires}`;
    document.cookie = `callee_phone_number=${signupData.value.callee_phone};path=/;domain=.storycall.org;${expires}`;

    // Clear stored signup data
    localStorage.removeItem('signupData');
    
    // Redirect to home instead of family_join since we're already logged in
    router.push('/');
  } catch (error) {
    console.error('Failed to complete signup:', error);
    alert('Failed to complete signup. Please try again.');
  } finally {
    showModal.value = false;
  }
};

const fillTestData = () => {
  signupData.value = {
    ...initialSignupState,
    family_slug: 'oakleafann38',
    family_password: 'aiforgood',
    callee_phone: '+18018083373',
    preferred_time: '18:00',
    family_timezone: 5, 
    caller_name: 'kid-212',
    caller_phone: '+12125187855',
    ig_white_list: ['wh1', 'wh2', ''],
    ig_verified: true,
    caller_verified: true,
    ig_username: 'oakleafann38'
  };
};
</script>

<style scoped>
@import '../assets/ig_signup.css';

.ig-auth-button {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
}

.status-icon {
  font-size: 1.5em;
}

.status-icon.success {
  color: #4CAF50;
}

.status-icon.failed {
  color: #f44336;
}

.info,
.error {
  padding: 15px;
  margin: 10px 0;
  border: none;
  background-color: transparent;
  font-size: 18px;
  font-weight: bold;
  border-radius: 0;
  color: #3b3b3b;
}

.error {
  border: 1px solid red;
}

.verification-code {
  display: block;
  font-size: 1.5em;
  margin-top: 10px;
  font-family: 'Courier New', Courier, monospace;
  text-align: center;
}

.connected-account {
  margin: 15px 0;
  color: #3b3b3b;
  border-radius: 4px;
  font-size: 16px;
}

.logo {
  max-width: 200px;
  margin: 0 auto 20px;
  display: block;
}

</style>
