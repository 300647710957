<template>
  <Modal />

  <div class="select-trigger" @click="showDropdown">
    <div class="select-overlay"></div>
    <div class="select-box centered-text">{{ message }}</div>
  </div>
  <div :class="styleDropdownClass" v-show="show" ref="dropdownRef">
    <ul>
      <li style="border-bottom: none;">
        <label class="selector-label centered-text" style="margin:0px">
          <div>
            {{ ui_string.pulldown_title }}
          </div>
        </label>
      </li>

      <li v-for="(option, index) in callee_list" :key="`option-${index}`">
        <label class="selector-label">
          <div>
            <input type="checkbox" :value="option.id" :checked="selected === option.id"
              @change="handleSelectionChange(option.id)" style="opacity: 0; position: absolute;" />
            <span></span>
            {{ option.callee_name }}
          </div>
          <div style="margin-left: auto; cursor: pointer; display: flex; align-items: center;">
            <a :href="`/callee_update/${option.id}`" style="color: #000000; z-index: 1000; padding: 15px 20px;">•••</a>
          </div>
        </label>
      </li>
      <li @click="navigateTo('/callee_create')">
        <label class="selector-label">
          <div class="selector-item">
            {{ ui_string.pulldown_callee_new }}
          </div>
        </label>
      </li>
      <li @click="navigateTo('/caller')">
        <label class="selector-label">
          <div class="selector-item">
            {{ ui_string.pulldown_caller_set }}
          </div>
        </label>
      </li>
      <li @click="setCallerIdToAll">
        <label class="selector-label">
          <div class="selector-item">
            {{ caller_all ? ui_string.pulldown_caller_everyone_off : ui_string.pulldown_caller_everyone }}
          </div>
        </label>
      </li>
      <li @click="setCalleeIdToAll">
        <label class="selector-label">
          <div class="selector-item">
            {{ callee_all ? ui_string.pulldown_callee_everyone_off : ui_string.pulldown_callee_everyone }}
          </div>
        </label>
      </li>

    </ul>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, defineExpose } from "vue";
import { useRouter } from "vue-router";
import Modal from "./Modal.vue";
import { storeToRefs } from 'pinia';
import { useSettingsStore } from "../store";

const settings = useSettingsStore();
const { showModal, callee_list, callee_id, callee_name, caller_id, callee_phone_number, caller_name, caller_phone_number, caller_all, callee_all, ui_string } = storeToRefs(settings);
const router = useRouter();

// New refs and computed properties
const show = ref(false);
const selected = ref(callee_id.value);
const multiselectRef = ref(null);
const dropdownRef = ref(null);

const message = computed(() => {
  if (!selected.value) {
    return "Choose who to call";
  } else if (caller_id.value === selected.value) {
    return `${caller_name.value} calls self - try it out`;
  } else {
    const selectedOption = callee_list.value.find((option) => option.id === selected.value);
    if (selectedOption) {
      if (caller_all.value && callee_all.value) {
        return `Everyone calls everyone`;
      } else if (caller_all.value) {
        return `Everyone calls ${selectedOption.callee_name}`;
      } else if (callee_all.value) {
        return `${caller_name.value} calls everyone`;
      } else {
        return `${caller_name.value} calls ${selectedOption.callee_name}`;
      }
    } else {
      return "Choose who to call";
    }
  }
});

const styleDropdownClass = "dropdown-container";

// Methods
const showDropdown = () => {
  show.value = true;
  setTimeout(() => {
    window.addEventListener("click", useDetectOutsideClick);
  }, 50);
};

const hideDropdown = () => {
  show.value = false;
  window.removeEventListener("click", useDetectOutsideClick);
};

const useDetectOutsideClick = (event) => {
  if (event.target !== dropdownRef.value && event.composedPath().includes(dropdownRef.value)) {
    return;
  }
  hideDropdown();
};

const setSelectedCallees = async (selectedCalleeId) => {
  showModal.value = true;
  const selectedCallee = callee_list.value.find(callee => callee.id === selectedCalleeId);
  if (selectedCallee) {
    callee_id.value = selectedCallee.id;
    callee_name.value = selectedCallee.callee_name;
    callee_phone_number.value = selectedCallee.phone_number;
    await settings.saveSettings();

    try {
      if (window.location.pathname.startsWith('/question_edit')) {
        router.push('/question_list/');
      } else if (window.location.pathname.startsWith('/call_history/')) {
        router.push('/call_history_list/');
      } else {
        await settings.fetchSettings();
      }
    } catch (error) {
      console.error("Error updating phone numbers:", error);
    } finally {
      showModal.value = false;
    }
  } else {
    console.error("Selected callee not found");
    showModal.value = false;
  }
};

const handleSelectionChange = async (value) => {
  if (selected.value !== value) {
    selected.value = value;
    callee_all.value = false;
    await setSelectedCallees(value);
  }
  hideDropdown();
};

const setCallerIdToAll = async () => {
  caller_all.value = !caller_all.value; 
  await updateSettings();
};

const setCalleeIdToAll = async () => {
  callee_all.value = !callee_all.value;
  await updateSettings();
};

const updateSettings = async () => {
  showModal.value = true;
  
  try {
    await settings.saveSettings();
    
    if (window.location.pathname.startsWith('/question_edit')) {
      router.push('/question_list/');
    } else if (window.location.pathname.startsWith('/call_history/')) {
      router.push('/call_history_list/');
    } else {
      await settings.fetchSettings();
    }
  } catch (error) {
    console.error("Error updating settings:", error);
  } finally {
    showModal.value = false;
  }
  
  hideDropdown();
};

const navigateTo = (path) => {
  router.push(path);
  hideDropdown();
};
// Watchers
watch(() => callee_list.value, (value) => {
  // Sort options if needed
}, { deep: true });

// Fetch settings on mount
onMounted(async () => {
  await settings.fetchSettings();
  selected.value = callee_id.value;
});

// Expose methods
defineExpose({
  showDropdown
});
</script>

<style>
@import "../assets/the_selector.css";

.selector-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 45px;
  cursor: pointer;
}

.centered-text {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-box {
  width: 100%;
  height: 40px;
  padding: 0;
  border: none;
  background-color: transparent;
  color: black;
  font-family: 'Lobster', cursive;
  font-size: 1.2em;
  cursor: pointer;
}

.select-trigger {
  position: relative;
  width: 100%;
}

.select-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

/* Remove the iOS-specific styles as they're no longer needed */
</style>
