<template>
  <div class="grid-table-container">
    <div class="grid-table">
      <TopBar /> 
      <ConfigNavBar />
      <!-- <div class="grid-table-cell-wide column">
        <div class="label-text">Your Number</div>
        <select v-model="selectedCaller">
          <option v-for="caller in callers" :key="caller.id" :value="caller.id">{{ caller.name }}</option>
        </select>
      </div> -->

      <div class="grid-table-cell-wide column">
        <div class="label-text">{{ui_string.family_timezone_title}}</div>
        <select v-model="timezone">
          <option value="5" selected>Eastern (New York)</option>
          <option value="6">Central (Chicago)</option>
          <option value="7">Mountain (Denver)</option>
          <option value="8">Pacific (San Francisco)</option>
        </select>
      </div>

      <IgWhiteList />

      <div class="grid-table-cell-wide column">
        <div class="label-text">{{ui_string.family_instruct_title}}</div>
        <textarea :placeholder="ui_string.family_instruct_default"v-model="family_instruct" class="textarea" rows="10" @blur="saveSettings"></textarea>
      </div>

      <!-- <div class="grid-table-cell-narrow">
        <button @click="saveSettings">
          <i class="fas fa-spinner fa-spin" v-if="showModalSaveSettings"></i>
          Save Settings
        </button>
      </div> -->

      <div class="grid-table-cell-narrow">
        <button @click="logout">
          {{ui_string.family_leave_button}}
        </button>
      </div>

      <div style="  margin-top: 50px;margin-bottom:10px">
        <span>{{ui_string.copyright_title}}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import { useSettingsStore } from "../store";
import { storeToRefs } from 'pinia';
import axios from "axios";
import ConfigNavBar from "./ConfigNavBar.vue";
import TopBar from "./TopBar.vue";



import IgWhiteList from "./IgWhiteList.vue";
const router = useRouter();
const settings = useSettingsStore();
const { caller_id, caller_name, caller_phone_number, family_instruct, BACK_URL, ui_string } = storeToRefs(settings);

const timezone = ref("5");

const showModalSaveSettings = ref(false);
const callers = ref([]);
const selectedCaller = ref(null);

watch(selectedCaller, (newCallerId) => {
  const selected = callers.value.find(caller => caller.id === newCallerId);
  if (selected) {
    caller_id.value = selected.id;
    caller_name.value = selected.name;
    caller_phone_number.value = selected.phone_number; // Set caller_phone_number
  }
});

watch(timezone, (newTimezone) => {
  saveSettings();
});

const fetchCallers = async () => {
  try {
    const response = await axios.get(`${BACK_URL.value}/caller/caller_get_all`, { withCredentials: true });
    callers.value = response.data;
    const matchingCaller = callers.value.find(caller => caller.id === caller_id.value);
    if (matchingCaller) {
      selectedCaller.value = matchingCaller.id;
    }
  } catch (error) {
    console.error("Failed to fetch callers:", error);
    alert("Failed to fetch callers");
  }
};

const saveSettings = async () => {
  showModalSaveSettings.value = true;
  settings.callee = settings.callee;
  settings.caller_id = caller_id.value;
  settings.caller_name = caller_name.value;
  settings.caller_phone_number = caller_phone_number.value; 
  settings.family_instruct = family_instruct.value;
  settings.timezone = timezone.value;
  await settings.saveSettings(); 
  await settings.fetchSettings();
  showModalSaveSettings.value = false;
  // router.push("/");
};

const logout = () => {
  settings.logout();
  router.push("/family_join");
};

onMounted(async () => {
  await settings.fetchSettings();
  caller_id.value = settings.caller_id;
  caller_name.value = settings.caller_name;
  caller_phone_number.value = settings.caller_phone_number;
  timezone.value = settings.timezone || "5";
  family_instruct.value = settings.family_instruct;
  await fetchCallers();
  window.scrollTo(0, 0);
});


</script>
<style scoped>
.NavBar {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 125px;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
}
</style>
