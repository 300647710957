<template>
    <div class="grid-table-cell-wide column">
      <div class="label-text">
        <div>Instagram Whitelist</div>
      </div>
      <div class="whitelist-input">
        <input 
          type="text" 
          v-model="newWhitelistUsername" 
          placeholder="Enter Instagram username"
          @keyup.enter="addToWhitelist"
        />
        <button @click="addToWhitelist">Add</button>
      </div>
      <div class="whitelist-container">
        <div 
          v-for="username in igWhitelist" 
          :key="username"
          class="whitelist-tag"
        >
          {{ username }}
          <span @click="removeFromWhitelist(username)">×</span>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import axios from 'axios';
  import { storeToRefs } from 'pinia';
  import { useSettingsStore } from '../store';
  
  const settings = useSettingsStore();
  const { BACK_URL, family_id } = storeToRefs(settings);
  
  const igWhitelist = ref([]);
  const newWhitelistUsername = ref('');
  
  const fetchWhitelist = async () => {
    try {
      const response = await axios.get(`${BACK_URL.value}/callee/ig_whitelist_get/${family_id.value}`);
      igWhitelist.value = response.data.ig_white_list;
    } catch (error) {
      console.error("Failed to fetch whitelist:", error);
      alert(`Failed to fetch Instagram whitelist: ${error.response?.data?.detail || error.message}`);
    }
  };
  
  const updateWhitelist = async () => {
    try {
      const payload = {
        family_id: family_id.value,
        ig_white_list: igWhitelist.value
      };
      await axios.put(`${BACK_URL.value}/callee/ig_whitelist_update`, payload);
    } catch (error) {
      console.error("Failed to update whitelist:", error);
      alert(`Failed to update Instagram whitelist: ${error.response?.data?.detail || error.message}`);
    }
  };
  
  const addToWhitelist = async () => {
    if (!newWhitelistUsername.value) return;
    
    if (!igWhitelist.value.includes(newWhitelistUsername.value.toLowerCase())) {
      igWhitelist.value.push(newWhitelistUsername.value.toLowerCase());
      await updateWhitelist();
      newWhitelistUsername.value = '';
    } else {
      alert('This username is already in the whitelist');
    }
  };
  
  const removeFromWhitelist = async (username) => {
    igWhitelist.value = igWhitelist.value.filter(u => u !== username);
    await updateWhitelist();
  };
  
  onMounted(async () => {
    await fetchWhitelist();
  });
  </script>
  
  <style scoped>
  .whitelist-tag {
    background: #f0f0f0;
    color: #333;
    padding: 4px 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin: 4px;
  }
  
  .whitelist-tag span {
    margin-left: 8px;
    cursor: pointer;
    color: red;
  }
  
  .whitelist-input {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .whitelist-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }





  
  </style>
