<template>
  <Modal />
  <div class="grid-table-container">
    <div class="grid-table">
      <TopBar />
      <div class="nav-bar"></div>
      <div class="content">
        <!-- Add New Number Section -->
        <div class="section">
          <h3>Add New Caller ID</h3>
          <input 
            v-model="newCallerId.phoneNumber" 
            type="tel" 
            placeholder="e.g. +1234567890"
          />
          <input 
            v-model="newCallerId.friendlyName" 
            type="text" 
            placeholder="Label (e.g. My Cell Phone)"
          />
          <button 
            @click="initiateCallerIdVerification"
            :disabled="isVerificationInProgress || !newCallerId.phoneNumber"
          >
            {{ isVerificationInProgress ? 'Verifying...' : 'Verify Number' }}
          </button>
          
          <div v-if="verificationCode" class="info">
            Enter this code when called: 
            <strong><span class="verification-code">{{ verificationCode }}</span></strong>
          </div>
          <div v-if="verificationError" class="error">{{ verificationError }}</div>
        </div>

        <!-- Verified Numbers Section -->
        <div class="section">
          <h3>Your Verified Numbers</h3>
          <div v-if="loading">Loading...</div>
          <div v-else-if="verifiedCallerIds.length === 0">No verified numbers yet</div>
          <div v-else class="numbers-list">
            <div v-for="callerId in verifiedCallerIds" :key="callerId.sid" class="number-item">
              <button @click="deleteCallerId(callerId.sid)" class="delete-btn">×</button>
              <div class="number">{{ callerId.phone_number }}</div>
              <div class="name">{{ callerId.friendly_name || 'Unnamed' }}</div>
              <div class="date">Verified: {{ formatDate(callerId.date_created) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import Modal from './Modal.vue';
import TopBar from './TopBar.vue';
import axios from 'axios';

const newCallerId = ref({ phoneNumber: '', friendlyName: '' });
const verificationCode = ref('');
const verificationError = ref('');
const isVerificationInProgress = ref(false);
const verifiedCallerIds = ref([]);
const loading = ref(false);

const initiateCallerIdVerification = async () => {
  try {
    verificationError.value = '';
    isVerificationInProgress.value = true;
    const response = await axios.post('https://back.dev.storycall.org/twilio/caller_id/add', {
      phone_number: newCallerId.value.phoneNumber,
      friendly_name: newCallerId.value.friendlyName
    });
    verificationCode.value = response.data.validation_code;
    await fetchVerifiedCallerIds();
  } catch (error) {
    verificationError.value = error.response?.data?.detail || 'Verification failed';
  } finally {
    isVerificationInProgress.value = false;
  }
};

const fetchVerifiedCallerIds = async () => {
  try {
    loading.value = true;
    const response = await axios.get('https://back.dev.storycall.org/twilio/caller_ids');
    verifiedCallerIds.value = response.data;
  } catch (error) {
    verificationError.value = 'Failed to load numbers';
  } finally {
    loading.value = false;
  }
};

const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString();
};

const deleteCallerId = async (sid) => {
  try {
    await axios.delete(`https://back.dev.storycall.org/twilio/caller_id/${sid}`);
    await fetchVerifiedCallerIds();
  } catch (error) {
    verificationError.value = 'Failed to delete number';
  }
};

onMounted(fetchVerifiedCallerIds);
</script>

<style scoped>
.grid-table-container {
  width: 100%;
}

.grid-table {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.content {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.section {
  margin: 30px 0;
}

input, button {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
}

.info, .error {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid;
}

.info {
  padding: 15px;
  margin: 10px 0;
  border: none;
  background-color: transparent;
  font-size: 1.1em;
  font-weight: bold;
  border-radius: 0;
}

.error { border-color: red; }

.numbers-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.number-item {
  padding: 15px;
  border: 1px solid #ddd;
  position: relative;
}

.number {
  font-weight: bold;
  margin-bottom: 5px;
}

.name {
  margin-bottom: 5px;
}

.date {
  font-size: 0.9em;
}

.delete-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  border-radius: 0;
  border: none;
  background: black;
  color: white;
  font-size: 18px;
  line-height: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  box-shadow: none;
}

.delete-btn:hover {
  background: #333;
}

.verification-code {
  display: block;
  font-size: 1.5em;
  margin-top: 10px;
  font-family: 'Courier New', Courier, monospace;
  text-align: center;
}
</style>
